import React, { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router';

// Bootstrap
import { Form } from 'react-bootstrap';

// Icons
import { MdCheckCircle, MdError } from 'react-icons/md';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// Components
import { Header } from '../../components/Header';

import {
  Container,
  TotalArea,
  UploadBar,
  MyForm,
  UploadArea,
  ButtonArea,
  MyButton,
  MessageSuccess,
  MessageError,
  LoadingArea,
  Loading,
  PercentUpload
} from './styles';

// Importando Context 
import { AuthenticationContext } from '../../contexts/Login';
import { isLogged, logout } from '../../helpers/Authentication';

// Axios
import axios from 'axios';

export function Upload() {

  let logged = isLogged();
  const history = useHistory();

  // Logout
  const handleLogout = () => {
    sessionStorage.removeItem('usuario')
    logout();
    history.push('/');
  }

  // Context User
  const {
    dadosUsario,
    dataUser
  } = useContext(AuthenticationContext);

  // States
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('usuario')));
  const [pattern, setPattern] = useState('');
  const [loading, setLoading] = useState(false);

  const [NewFiles, setNewFiles] = useState('');

  const [status, setStatus] = useState({
    type: '',
    message: ''
  });

  const filesElement = useRef(null);
  const [percent, setPercent] = useState(0);

  // Envia o arquivo pro backend
  const handleSubmitTexto = async (event) => {
    event.preventDefault();

    if (NewFiles) {
      setPercent(0);
      setStatus({ type: '', message: '' });
      setLoading(true);

      // FormData do arquivo
      const dataFile = new FormData();
      for (const file of filesElement.current.files) {
        dataFile.append('file', file);
      }

      // UploadProgress
      const options = {
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          setPercent(Math.floor((loaded * 100) / total));
          //console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        }
      }

      // Requisição via Axios
      axios.post("http://localhost/ApiContraCheque/public/api/data", dataFile, options)
        .then(response => {
          setLoading(false);
          setStatus({
            type: "sucesso",
            message: 'Upload feito com sucesso!'
          });
        })
        .catch(function (error) {
          setLoading(false);
          setStatus({
            type: "error",
            message: 'Falha ao carregar o arquivo!'
          });
        })
    } else {
      setLoading(false);
      setStatus({
        type: "error",
        message: 'Error: Nenhum arquivo selecionado!'
      });
    }
  }

  return (
    <Container>
      {logged &&

        <>
          <Header handleLogout={handleLogout} user={user?.[0]['usuario']} />

          <TotalArea>
            <UploadBar>
              <MyForm encType="multipart/form-data">
                <UploadArea>

                  <Form.Group className="formFileSm">
                    <Form.Control
                      type="file"
                      size="sm"
                      name="file"
                      multiple ref={filesElement}
                      onChange={(e) => setNewFiles(e.target.name)}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                  </Form.Group>

                  <Form.Group className="formFileSm">
                    <Form.Label className="formLabel">Padrão de arquivo:</Form.Label>
                    <Form.Select style={{ width: '150px' }} size="sm" value={pattern} onChange={e => setPattern(e.target.value)}>
                      <option value='0'> Selecione...</option>
                      <option value='SAAP'> Padrão SAAP</option>
                      <option value='PROPRIO'> Padrao Próprio</option>
                    </Form.Select>
                  </Form.Group>

                </UploadArea>
                <ButtonArea>
                  <MyButton onClick={handleSubmitTexto}>Enviar</MyButton>
                </ButtonArea>
              </MyForm>
            </UploadBar>

            {status['type'] == 'sucesso' && <MessageSuccess>{status.message}</MessageSuccess>}
            {status['type'] == 'error' && <MessageError>{status.message}</MessageError>}

            <LoadingArea>
              <Loading>
                {loading && (
                  <>
                    <PercentUpload>{percent}%</PercentUpload>
                    <CircularProgressbar
                      styles={{
                        root: { width: 130 },
                        path: { stroke: '#005693' }
                      }}
                      strokeWidth={10}
                      value={percent}
                    />
                  </>
                )}
              </Loading>

              {status['type'] == 'sucesso' && <MdCheckCircle size={150} color="#28a745" />}
              {status['type'] == 'error' && <MdError size={150} color="#dc3545" />}
            </LoadingArea>

          </TotalArea>
        </>
      }
      {
        !logged &&
        history.push('/')
      }
    </Container>
  );
}