import React, { createContext, useState } from 'react';
import { useHistory } from "react-router-dom";

import useApi from '../helpers/Api'
import { login } from '../helpers/Authentication';

export const AuthenticationContext = createContext();
export function AuthenticationProvider(props) {

  const api = useApi();

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberPassword, setRememberPassword] = useState(false);
  const [dataUser, setDataUser] = useState('Jailson');
  const [dadosUsario, setDadosUsuario] = useState([]);

  // State responsável, por exibir o status de success ou error!
  const [status, setStatus] = useState({
    type: '',
    message: ''
  });

  const handleSubmitLoginUser = async (event) => {
    event.preventDefault();

    const response = await api.loginUser(email, password);

    setDadosUsuario({ ...response.data })

    if (response.status === "error") {
      setStatus({
        type: "error",
        message: 'Erro: Email e/ou senha inválido!'
      });
    } else {
      sessionStorage.setItem('usuario', JSON.stringify(response.data));
      login(response.data[0]['token'], rememberPassword);
      window.location.href = '/search';
    }
  }

  return (
    <AuthenticationContext.Provider
      value={
        {
          email,
          setEmail,
          password,
          setPassword,
          rememberPassword,
          setRememberPassword,
          dadosUsario,
          dataUser,
          status,
          handleSubmitLoginUser
        }
      }>
      {props.children}
    </AuthenticationContext.Provider>
  );
}