import React, { useState } from 'react';
import {
  Container,
  Main,
  SectionLeft,
  Image,
  TextSpan,
  MyLink,
  SectionRight,
  TextPassword,
  TextTitle,
  TextDescription,
  AreaButton
} from './styles';
import Logo from '../../assets/Logo.svg';
import { Form, Button } from 'react-bootstrap';

export function ResetPassword() {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubimit = async (e) => {
    e.prevent.default();

    const data = {
      token: this.props.match.params.id,
      password: this.password,
      confirmPassword: this.confirmPassword
    }

    // Fazer a validação da senha e confirmar senha

    // Função que salva as senhas no banco de dados

    // Fazer um redirect pra login

  };

  return (
    <Container>

      <Main>
        <SectionLeft>
          <Image src={Logo} alt="Logo da aplicação" />
          <TextTitle>Bem Vindo de Volta!</TextTitle>
          <TextDescription>Acesse sua conta agora mesmo</TextDescription>
          <MyLink to="/">
            <Button variant="light" type="button">ENTRAR</Button>
          </MyLink>
          <MyLink to="/register">
            <TextSpan>Fazer cadastro</TextSpan>
          </MyLink>
        </SectionLeft>

        <SectionRight>
          <TextPassword>ALTERAR SENHA</TextPassword>

          <Form onSubmit={handleSubimit}>
            <Form.Group className="mb-2" controlId="formGridPassword">
              <Form.Label className="mb-0">Senha</Form.Label>
              <Form.Control size="sm" type="password" name="password" placeholder="Digite sua nova senha"
                value={password}
                onChange={(event) => setPassword(event.target.value)} />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formGridPassword">
              <Form.Label className="mb-0">Confirmar senha</Form.Label>
              <Form.Control size="sm" type="password" name="ResetPassword" placeholder="Repita a nova senha"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)} />
            </Form.Group>
            <AreaButton>
              <Button type="button">
                CONFIRMAR
              </Button>
            </AreaButton>
          </Form>
        </SectionRight>
      </Main>

    </Container>
  )
}