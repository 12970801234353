import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useApi from '../../helpers/Api';
import { Table } from 'react-bootstrap';

import { Form } from 'react-bootstrap';

// Import de components e Styled-components
import { Header } from '../../components/Header';
import {
  Container,
  SearchBar,
  MyForm,
  CompetenceArea,
  InputArea,
  /*  InputLinkArea, */
  AreaCompetencys,
  LinkArea,
  LabelLink,
  CompetencePeriod,
  SearchArea,
  SearchButton,
  MessageError,


  TableHead,
  TableBody,
  TableRow,
  TableH,
  TableData,
  MyButton
} from './styles';

// Importando Context API
import { AuthenticationContext } from '../../contexts/Login';
import { isLogged, logout } from '../../helpers/Authentication';
import contraChequePDF from '../../reports/funcionarios';
import { Fragment } from 'react';

export function Search() {

  const {
    dadosUsario,
    dataUser
  } = useContext(AuthenticationContext);

  let logged = isLogged();
  const history = useHistory();
  const api = useApi();

  const handleLogout = () => {
    sessionStorage.removeItem('usuario')
    logout();
    history.push('/');
  }

  const [startOfCompetency, setStartOfCompetency] = useState('');
  const [endOfCompetency, setEndOfCompetency] = useState('');
  const [contract, SetContract] = useState('Todos');
  const [listCompetencys, setListCompetencys] = useState([]);
  const [competencysSelected, setCompetencysSelected] = useState([]);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('usuario')));

  const [idUser, setIdUser] = useState(user?.[0]['id_func']);

  const [status, setStatus] = useState({
    type: '',
    message: ''
  });

  //  Seleciona todos os vinculos relacionado a um funcionario
  useEffect(() => {

    const getCompetencys = async (idUser) => {
      const response = await api.allCompetencysUser(idUser);

      // setListCompetencys(response.data);
      if (response.status === "error") {
        setStatus({
          type: "error",
          message: 'Erro: Não foi possivel localizar as informações!'
        });
      } else {
        setListCompetencys(response.data);
      }
    }
    getCompetencys(idUser);
  }, []);

  // console.log(idUser); id do usuario logado

  // Seleciona todas as competencias dentro de um intervalo ligadas a um vínculo
  const handleSubmitCompetencys = async (event) => {
    event.preventDefault();

    if (startOfCompetency !== '' && endOfCompetency !== '' && contract !== '') {
      const response = await api.allCompetencys({
        startOfCompetency,
        endOfCompetency,
        contract,
        idUser
      });

      console.log(response.data)

      if (response.status == 'sucesso') {
        setCompetencysSelected(response.data);
        setStatus({
          type: "sucesso",
          message: ''
        });
      } else if (response.status === "error") {
        setStatus({
          type: "error",
          message: 'Nenhuma competência encontrada!'
        });
        setCompetencysSelected([]);
      }
    }
  }

  return (
    <Container>
      {logged &&

        <>
          <Header handleLogout={handleLogout} user={user?.[0]['usuario']} />
          <AreaCompetencys>
            <SearchBar>
              <MyForm method="GET">
                <CompetenceArea>
                  <LabelLink>COMPETÊNCIA </LabelLink>
                  <CompetencePeriod>
                    <LabelLink>DE: &nbsp;
                      <InputArea type="month" name='startOfCompetency' value={startOfCompetency}
                        onChange={(event) => setStartOfCompetency(event.target.value)} />
                    </LabelLink>
                    <LabelLink>&nbsp;ATE: &nbsp;
                      <InputArea type="month" name='endOfCompetency' value={endOfCompetency}
                        onChange={(event) => setEndOfCompetency(event.target.value)} />
                    </LabelLink>
                  </CompetencePeriod>
                </CompetenceArea>
                <LinkArea>
                  <LabelLink>VÍNCULO</LabelLink>
                  <SearchArea>
                    <Form.Select style={{ width: '220px' }} size="sm" value={contract} onChange={e => SetContract(e.target.value)}>
                      <option value="Todos"> Todos </option>
                      {
                        listCompetencys.map((compet, index) => (
                          <Fragment key={index}>
                            <option value={compet.id} >{compet.cargo}</option>
                          </Fragment>
                        ))}
                    </Form.Select>
                    <SearchButton type="button" onClick={handleSubmitCompetencys} />
                  </SearchArea>
                </LinkArea>
              </MyForm>
            </SearchBar>

            <Table responsive className='w-75 mx-auto text-center'>
              <TableHead>
                <TableRow>
                  <TableH>COMPTÊNCIAS</TableH>
                  <TableH>CARGOS</TableH>
                  <TableH>VISUALIZAR</TableH>
                </TableRow>
              </TableHead>
              <TableBody>
                {competencysSelected &&
                  competencysSelected?.map((compet, index) => (
                    <TableRow key={index} value={compet}>
                      <TableData>{compet.competencia.substr(-2, 2) + "-" + compet.competencia.substr(0, 4)}</TableData>
                      <TableData>{compet.cargo}</TableData>
                      <TableData><MyButton onClick={(e) => contraChequePDF(compet)} target="_blank">Gerar PDF</MyButton></TableData>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            {status.message &&
              <MessageError>{status.message}</MessageError>
            }
          </AreaCompetencys>
        </>
      }
      {
        !logged &&
        history.push('/')
      }
    </Container >
  )
}