import styled from "styled-components";

// Estilização Mobile First
const Container = styled.div`
  display: flex;
`
const TotalArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px auto;
`;

const UploadBar = styled.div`
  margin: 11vh auto;
  width: 98vw;
  background-color: transparent;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  
  @media only screen and (min-width: 769px) {
    height: 11vh;
    width: 84vw;
    margin: 5px auto;
    
    margin-bottom: 20%;
    background-color: #005693;
  } 
`
const MyForm = styled.form`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1 solid #005693;
  border-radius: 15px;
  background-color: #005693;
  height: 150px;
  padding: 10px;

  @media only screen and (min-width: 769px) {
    width: 80vw;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px;
  }
`
const UploadArea = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    .formFileSm{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      gap: 5px;
      margin-bottom: 5px;

      .formLabel{
        margin: 0;
      }
    }

  @media only screen and (min-width: 769px) {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    .formFileSm{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 5px;

      .formLabel{
        margin: 0;
      }
    }
  }
`
const ButtonArea = styled.div``

const MyButton = styled.button`
  width: 130px;
  height: 40px;
  background-color: #fff;
  font-size: 20px;
  color: #005693;
  font-weight: bolder;
  border: 2px solid #fff;
  border-radius: 50vw;

  &:hover{
    cursor: pointer;
    font-weight: bold;
    background-color: rgba(233,236,239,0.99);  
    border: 2px solid rgba(233,236,239,0.99);
  }  
`
const MessageSuccess = styled.p`
  color: #28a745;
`
const MessageError = styled.p`
  color: #dc3545;
`
const LoadingArea = styled.div`
`
const Loading = styled.div`
  display: flex;
  justify-content: center;
`
const PercentUpload = styled.div`
  position: absolute;
  margin: 45px auto;
  font-size: 25px;
  font-weight: bolder;
  color: #005693;
`;

export {
  Container, TotalArea, UploadBar,
  MyForm, UploadArea, ButtonArea, 
  MyButton, MessageSuccess, MessageError, 
  LoadingArea, Loading, PercentUpload
}