import styled from "styled-components";
import { RiSearchLine } from 'react-icons/ri';

// Estilização Mobile First
const Container = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
`
const AreaCompetencys = styled.div`
padding: 0;
margin: 0;
width: 100vw;
`
const SearchBar = styled.div`
  margin: 11vh auto 0;
  width: 98vw;
  background-color: transparent;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;

  @media only screen and (min-width: 769px) {
    height: 11vh;
    width: 84vw;
    margin: 5px auto;
    background-color: #005693;
  }
`
const MyForm = styled.form`
  width: 100vw;
  display: flex;
  flex-direction: column;   
  border: 1 solid #005693;
  border-radius: 15px;
  background-color: #005693;
  height: 170px;

  @media only screen and (min-width: 769px) {
    width:80vw;
    flex-direction: row;  
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: auto;
  }
`
const CompetenceArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const InputArea = styled.input`
  height: 30px;
  min-width: 220px;
  border: 2px solid #fff;
  border-radius: 5px;
  outline: none;
`
/* const InputLinkArea = styled.input`
  height: 30px;
  min-width: 220px;
  border: 2px solid #fff;
  border-radius: 5px;
  outline: none;

  @media only screen and (min-width: 769px) { 
    margin-left: 0;
  }
` */

const LinkArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const LabelLink = styled.label`
  margin: 2px;
`
const CompetencePeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
`
const SearchArea = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding: auto;

  @media only screen and (min-width: 769px) {
   flex-direction: row;
  }
`
const SearchButton = styled(RiSearchLine)`
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  z-index:0;
  @media only screen and (min-width: 769px) {
    margin-left: 2px;
    width: 35px;
    height: 35px;
    transform: rotate(0deg);
  }
`
const MessageError = styled.p`
  padding-left: 13%;
  color: #dc3545;
`
const SelectCompetency = styled.select`

`
// Estilização da tabela
const TableHead = styled.thead``

const TableBody = styled.tbody``

const TableRow = styled.tr``

const TableH = styled.th``

const TableData = styled.td``

const MyButton = styled.button`
  background-color: #005693;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  border: none;
  outline: none;

  &:hover{
  cursor: pointer;
  background-color: rgba(0,86,147, 0.9)
  }
`
export {
  Container, SearchBar, MyForm, CompetenceArea, InputArea, AreaCompetencys,
  LinkArea, LabelLink, CompetencePeriod, SearchArea, SearchButton, MessageError,

  SelectCompetency,

  TableHead, TableBody, TableRow, TableH, TableData, MyButton
}