import styled from "styled-components";
import { Link } from "react-router-dom";

// Estilização Mobile First
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 20px;

  @media only screen and (min-width: 769px) {
    width: 55vw;
    flex-direction: row;
  }
`
const SectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: auto;
  background-color: #005693;
  color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 10px;

  @media only screen and (min-width: 769px) {
    width: 30vw;
    padding: 20px;
    border-radius: 20px 0 0 20px;
  }
`
const Image = styled.img`
  max-width: 80px;
  
  @media only screen and (min-width: 769px) {
    max-width: 120px;
  }
`
const TextSpan = styled.span`
  color: #fff;
  font-style: italic;
  font-size: 12px;

  &:hover{
    text-decoration: underline;
  }
`
const MyLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`
const TextPassword = styled.h1`
  display: flex;
  justify-content: center;
  font-weight: bolder;
  color: #005693;
  font-size: 20px;

  @media only screen and (min-width: 769px) {
    font-size: 35px;
  }
`
const TextTitle = styled.h3`
`
const TextDescription = styled.p`
`
const SectionRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: 30vh;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  
  @media only screen and (min-width: 769px) {
    width: 70vw;
    height: auto;
    padding: 20px;
  } 

  button{
    background-color: #005693;
    border: 1px solid #005693;

    &:hover{
      background-color: rgba(0,86,147, 0.9);
      border: 1px solid rgba(0,86,147, 0.9);
    }
  }
`
const AreaButton = styled.div`
  display: flex;
  justify-content: center;
`
export {
  Container, Main, SectionLeft, Image, TextSpan, MyLink, SectionRight, 
  TextPassword, TextTitle, TextDescription, AreaButton
}