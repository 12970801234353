import Cookies from 'js-cookie';
import qs from 'qs';

//const BASEAPI = "http://localhost/ApiContraCheque/public/api";

// Dominio HostGator
const BASEAPI = 'https://contracheque.morohsoftware.com/Api/public/api';

const apiFetchPost = async (endpoint, body) => {

  if (!body.token) {
    let token = Cookies.get('token');
    if (token) {
      body.token = token;
    }
  }

  const request = await fetch(BASEAPI + endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    body: JSON.stringify(body)
  });

  const response = await request.json();
  return response;
}

const apiFetchGet = async (endpoint, body = []) => {

  if (!body.token) {
    let token = Cookies.get('token');
    if (token) {
      body.token = token;
    }
  }

  const request = await fetch(`${BASEAPI + endpoint}?${qs.stringify(body)}`);
  const response = await request.json();

  return response;
}

const api = {

  // Register User
  register: async (registerUser) => {
    const response = await apiFetchPost('/usuario', registerUser);
    return response;
  },

  // All Competencys
  allCompetencys: async (options) => {
    const response = await apiFetchGet(
      '/usuario',
      options
    );
    return response;
  },
  // All Competencys User
  allCompetencysUser: async (idUser) => {
    const response = await apiFetchGet(
      `/usuario/${idUser}`
    );
    return response;

  },
  // Login
  loginUser: async (email, password) => {
    const response = await apiFetchPost(
      '/login',
      { email, password }
    );
    return response;
  },
  // Recover Password
  recoverPassword: async (email) => {
    const response = await apiFetchPost(
      '/recoverpassword',
      { email }
    );
    return response;
  },
  // Save Data Txt
  saveDataTxt: async (files) => {
    const response = await apiFetchPost(
      '/data',
      { files }
    );
    return response;
  },
}

export default () => api;