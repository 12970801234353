import React from 'react';

// Estilizando Componentes
import {
  Container,
  BurgerMenu,
  CheckInput,
  IconLabel,
  ImageMenuBurger,
  NavigatorBar,
  NavArea,
  MyLink,
  LinkArea,
  NormalMenu,
  AreaLogo,
  ImageLogo,
  LinhaHr,
  MenuArea,
  MenuItem,
  ImageHome,
  ImageSearch,
  ImageAttachment,
  ImageLogout,
  WelcomeUserArea,
  WelcomeUser,
} from './styles';

// Imagens SVG
import Logo from '../../assets/Logo.svg';

export function Header({ handleLogout, user }) {

  return (
    <Container>
      <BurgerMenu>
        <CheckInput type="checkbox" id="check" />
        <IconLabel id="icone" htmlFor="check">
          <ImageMenuBurger />
          <WelcomeUserArea>
            {user &&
              <>
                <WelcomeUser>Bem vindo!</WelcomeUser>
                <WelcomeUser>{user?.split(' ', 1)}</WelcomeUser>
              </>
            }
          </WelcomeUserArea>
        </IconLabel>

        <NavigatorBar className="barra">
          <NavArea>
            <MyLink to="#">
              <LinkArea className="link">
                <ImageHome />
                Início
              </LinkArea>
            </MyLink>
            <MyLink to="/search">
              <LinkArea className="link">
                <ImageSearch />
                Pesquisar
              </LinkArea>
            </MyLink>
            <MyLink to="/upload">
              <LinkArea className="link">
                <ImageAttachment />
                Upload
              </LinkArea>
            </MyLink>
            <MyLink onClick={handleLogout}>
              <LinkArea className="link">
                <ImageLogout />
                Sair
              </LinkArea>
            </MyLink>
          </NavArea>
        </NavigatorBar>
      </BurgerMenu>
      <NormalMenu>

        <AreaLogo>
          <ImageLogo src={Logo} alt="Logo da aplicação" />
          <WelcomeUserArea>
            {user &&
              <>
                <WelcomeUser>Bem vindo!</WelcomeUser>
                <WelcomeUser>{user?.split(' ', 1)}</WelcomeUser>
              </>
            }
          </WelcomeUserArea>
          <LinhaHr />
        </AreaLogo>

        <MenuArea>
          <MenuItem>
            <ImageHome />
            Início
          </MenuItem>
          <MyLink to="/search">
            <MenuItem>
              <ImageSearch />
              Pesquisar
            </MenuItem>
          </MyLink>
          <MyLink to="/upload">
            <MenuItem>
              <ImageAttachment />
              Upload
            </MenuItem>
          </MyLink>
          <MenuItem onClick={handleLogout}>
            < ImageLogout />
            Sair
          </MenuItem>
        </MenuArea>
      </NormalMenu>
    </Container>
  )
}