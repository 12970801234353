import Cookies from 'js-cookie';

// Se estive logado recupera o Token, do Cookie
export const isLogged = () => {
  let token = Cookies.get('token');
  return (token) ? true : false;
}

// Faz login, e usa o token e lemprar senha para monitorar o tempo de sessão
export const login = (token, rememberPassword = false) => {
  if (rememberPassword) {
    Cookies.set('token', token, { expires: 90 })
  } else {
    Cookies.set('token', token)
  }
}

// Faz o lougout e remove o token do cookie
export const logout = () => {
  Cookies.remove('token');
}
