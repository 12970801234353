import React, { useContext } from 'react';
import {
  Container,
  Main,
  SectionLeft,
  Image,
  MyLink,
  TextTitle,
  TextDescription,
  TextLogin,
  TextSpan,
  SectionRight,
  AreaButton
} from './styles';
import Logo from '../../assets/Logo.svg';

import { Form, Button } from 'react-bootstrap';

// Importando Context API
import { AuthenticationContext } from '../../contexts/Login';

export function Login() {

  const {
    status,
    email,
    setEmail,
    password,
    setPassword,
    rememberPassword,
    setRememberPassword,
    handleSubmitLoginUser
  } = useContext(AuthenticationContext);

  return (
    <Container>

      <Main>
        <SectionLeft>
          <Image src={Logo} alt="Logo da aplicação" />
          <TextTitle>Bem Vindo de Volta!</TextTitle>
          <TextDescription>Acesse sua conta agora mesmo</TextDescription>
          <MyLink to="/register">
            <Button variant="light" type="button">CADASTRAR</Button>
          </MyLink>
          <MyLink to="/recoverpassword">
            <TextSpan>Esqueci minha Senha</TextSpan>
          </MyLink>
        </SectionLeft>

        <SectionRight>
          <TextLogin>LOGIN</TextLogin>
          {status.type === 'success' ? <p style={{ color: "#28a745" }}>{status.message}</p> : ""}
          {status.type === 'error' ? <p style={{ color: "#dc3545" }}> {status.message}</p> : ""}
          <Form>
            <Form.Group className="mb-2" controlId="formGridEmail">
              <Form.Label className="mb-0">E-mail</Form.Label>
              <Form.Control size="sm" type="email" name="email" placeholder="Digite seu email"
                value={email}
                onChange={(event) => setEmail(event.target.value)} />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formGridPassword">
              <Form.Label className="mb-0">Senha</Form.Label>
              <Form.Control size="sm" type="password" name="password" placeholder="Digite sua Senha"
                value={password}
                onChange={(event) => setPassword(event.target.value)} />
            </Form.Group>
            <Form.Group className="mb-2 d-flex">
              <Form.Check size="sm" type="checkbox" id="rememberPassword" checked={rememberPassword}
                onChange={() => setRememberPassword(!rememberPassword)} />
              <Form.Label className="mx-2" htmlFor="rememberPassword">Lembrar Senha</Form.Label>
            </Form.Group>
            <AreaButton>
              <MyLink to="#">
                <Button type="button" onClick={handleSubmitLoginUser}>
                  ENTRAR
                </Button>
              </MyLink>
            </AreaButton>
          </Form>
        </SectionRight>
      </Main>

    </Container>
  )
}