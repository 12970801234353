import React from 'react';
// Importando o BrowserRouter
import { BrowserRouter } from 'react-router-dom';
//Importando as rotas
import Routes from './routes';
import './App.css';

function App() {
  return (
    /*    <div className="App">
         <header className="App-header">
           <h1>Olá Mundo!</h1>
         </header>
       </div> */

    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
