import React, { useState } from 'react';
import {
  Container,
  Main,
  SectionLeft,
  Image,
  TextSpan,
  MyLink,
  SectionRight,
  TextPassword,
  TextTitle,
  TextDescription,
  AreaButton
} from './styles';
import Logo from '../../assets/Logo.svg';
import { Form, Button } from 'react-bootstrap';

import useApi from '../../helpers/Api';

export function RecoverPassword() {

  const api = useApi();

  const [email, setEmail] = useState('')
  const [dataUser, setDataUser] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState({
    type: "",
    message: ""
  });

  // Seleciona todas as competencias dentro de um intervalo ligadas a um vínculo
  const handleSubmitRecoverPassword = async (event) => {
    event.preventDefault();
    setStatus({
      type: "",
      message: ""
    });

    setDisabled(true);

    const response = await api.recoverPassword(email);

    // console.log(response);
    // setListCompetencys(response.data);
    if (response.status === "error") {
      setStatus({
        type: "error",
        message: "Erro: E-mail inválido!"
      });
      setDisabled();
    } else {
      setDataUser(response.data);
      setStatus({
        type: "success",
        message: "Um link para rededinir sua senha, foi enviado por email."
      });
    }
    setDisabled(false);
  }

  return (
    <Container>

      <Main>
        <SectionLeft>
          <Image src={Logo} alt="Logo da aplicação" />
          <TextTitle>Bem Vindo de Volta!</TextTitle>
          <TextDescription>Acesse sua conta agora mesmo</TextDescription>
          <MyLink to="/">
            <Button variant="light" type="button">ENTRAR</Button>
          </MyLink>
          <MyLink to="/register">
            <TextSpan>Fazer cadastro</TextSpan>
          </MyLink>
        </SectionLeft>

        <SectionRight>
          <TextPassword>RECUPERAR SENHA</TextPassword>

          {status.type === 'success' ? <p style={{ color: "#28a745" }}>{status.message}</p> : ""}
          {status.type === 'error' ? <p style={{ color: "#dc3545" }}> {status.message}</p> : ""}

          <Form onSubmit={handleSubmitRecoverPassword}>
            <Form.Group className="mb-2" controlId="formGridEmail">
              <Form.Label className="mb-0">Email</Form.Label>
              <Form.Control size="sm" type="email" placeholder="Digite seu email"
                value={email}
                required
                disabled={disabled}
                onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <AreaButton>
              <Button variant="primary" type="submit" disabled={disabled} >
                ENVIAR
              </Button>
            </AreaButton>
          </Form>
        </SectionRight>
      </Main>

    </Container>
  )
}