import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { AuthenticationProvider } from './contexts/Login';

/* 
import { Header } from './components/Header';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Search } from './pages/Search';
import { Upload } from './pages/Upload';
import { RecoverPassword } from './pages/RecoverPassword'; */

ReactDOM.render(
  <AuthenticationProvider>
    {/*   <Search /> 
    {/*   <Upload /> */}
    {/*   <Login /> */}
    {/*   <Register />   */}
    {/*   <RecoverPassword />  */}

    <App />
  </AuthenticationProvider>,
  document.getElementById('root')
);
