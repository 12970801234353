import React, { useState } from 'react';

import {
  Container,
  Main,
  SectionLeft,
  Image,
  MyLink,
  TextTitle,
  TextDescription,
  TextRegister,
  TextSpan,
  SectionRight,
  AreaButton
} from './styles';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Logo from '../../assets/Logo.svg';

import useApi from '../../helpers/Api';
import { cpfMask } from '../../helpers/Mask';

import { useHistory } from 'react-router';

export function Register() {

  const history = useHistory();

  const api = useApi();

  const [registerUser, setRegisterUser] = useState([
    {
      userName: '',
      cpf: '',
      office: '',
      admissionDate: '',
      motherName: '',
      email: '',
      password: '',
      id: '',
      lk_funcionario: '',
      ultimo_acesso: '',
      status: '',
      nivel: ''
    }
  ]);

  // State responsável, por exibir o status de success ou error!
  const [status, setStatus] = useState({
    type: '',
    message: ''
  });

  // Captura os dados vindo dos inputs do formulário de cadastro de usário
  const handleChange = async (index, event) => {
    const values = [...registerUser];

    // Aplicando Mascara para o campo CPF
    if (event.target.name === 'cpf') {
      values[index]['cpf'] = (cpfMask(event.target.value));
    } else {
      values[index][event.target.name] = event.target.value;
    }
    setRegisterUser(values);
  }

  const handleSubmitRegisterUser = async (event) => {
    event.preventDefault();

    // Faz a validação antes de mandar os dados para salvar no banco de dados
    if (!ValidateData()) return;

    const json = await api.register(registerUser[0]);

    if (json.status === "error") {
      setStatus({
        type: "error",
        message: 'Erro: Verifique se os campos foram preenchidos corretamente!'
      });
    } else {
      setStatus({
        type: "success",
        message: "Usuário cadastrado com sucesso!"
      });
      history.push('/search');
    }
  }

  // Validando todos os campos do formulário
  function ValidateData() {
    if (!registerUser[0].userName) return setStatus({ type: 'error', message: 'Error: Necessário preencer o campo nome!' });
    if (!registerUser[0].cpf) return setStatus({ type: 'error', message: 'Error: Necessário preencer o campo cpf!' });
    if (!registerUser[0].office) return setStatus({ type: 'error', message: 'Error: Necessário preencer o campo Cargo!' });
    if (!registerUser[0].admissionDate) return setStatus({ type: 'error', message: 'Error: Necessário preencer o campo data de admissão!' });
    if (!registerUser[0].motherName) return setStatus({ type: 'error', message: 'Error: Necessário preencer o campo nome da mãe!' });
    if (!registerUser[0].email) return setStatus({ type: 'error', message: 'Error: Necessário preencer o campo email!' });
    if (!registerUser[0].password) return setStatus({ type: 'error', message: 'Error: Necessário preencer o campo senha!' });
    if (registerUser[0].password.length < 8) return setStatus({ type: 'error', message: 'Error: O campo senha não pode ter menos de 8 caracteres!' });
    return true;
  }

  return (
    <Container>

      <Main>
        <SectionLeft>
          <Image src={Logo} alt="Logo da aplicação" />
          <TextTitle>Bem Vindo de Volta!</TextTitle>
          <TextDescription>Acesse sua conta agora mesmo</TextDescription>
          <MyLink to="/">
            <Button variant="light" type="button">
              ENTRAR
            </Button>
          </MyLink>
          <MyLink to="/recoverpassword">
            <TextSpan>Esqueci minha Senha</TextSpan>
          </MyLink>
        </SectionLeft>

        <SectionRight>
          <TextRegister>FAÇA SEU CADASTRO</TextRegister>
          {status.type === 'success' ? <p style={{ color: "#28a745" }}>{status.message}</p> : ""}
          {status.type === 'error' ? <p style={{ color: "#dc3545" }}> {status.message}</p> : ""}
          {
            registerUser?.map((register, index) => (
              <Form key={index} method="POST">
                <Row className="mb-2">
                  <Col xs={12} md={8}>
                    <Form.Label className="mb-0">Nome</Form.Label>
                    <Form.Control size="sm"
                      type="text"
                      placeholder="Digite seu nome"
                      name="userName"
                      required
                      value={register?.userName}
                      onChange={event => handleChange(index, event)} />
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label className="mb-0">CPF</Form.Label>
                    <Form.Control size="sm"
                      type="text"
                      placeholder="Ex: 000.000.000-00"
                      maxLength="14"
                      name="cpf"
                      required
                      value={register?.cpf}
                      onChange={event => handleChange(index, event)} />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={12} md={8}>
                    <Form.Label className="mb-0">Cargo</Form.Label>
                    <Form.Control size="sm"
                      type="text"
                      placeholder="Digite seu cargo"
                      name="office"
                      required
                      value={register?.office}
                      onChange={event => handleChange(index, event)} />
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label className="mb-0">Data de Admissão</Form.Label>
                    <Form.Control size="sm"
                      type="date"
                      placeholder="Ex: 12-02-2001"
                      name="admissionDate"
                      required
                      value={register?.admissionDate}
                      onChange={event => handleChange(index, event)} />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Label className="mb-0">Nome da Mãe</Form.Label>
                    <Form.Control size="sm"
                      placeholder="Digite o nome da sua mãe"
                      name="motherName"
                      required
                      value={register?.motherName}
                      onChange={event => handleChange(index, event)} />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Label className="mb-0">E-mail</Form.Label>
                    <Form.Control size="sm"
                      type="email"
                      placeholder="Digite seu email"
                      name="email"
                      required
                      value={register?.email}
                      onChange={event => handleChange(index, event)} />
                  </Col>
                </Row>
                <Form.Group className="mb-2" controlId="formGridPassword">
                  <Form.Label className="mb-0">Senha</Form.Label>
                  <Form.Control size="sm"
                    type="password"
                    placeholder="Digite sua Senha"
                    name="password"
                    required
                    value={register?.password}
                    onChange={event => handleChange(index, event)} />
                </Form.Group>
                <AreaButton>
                  <Button variant="primary" type="button" onClick={handleSubmitRegisterUser}>
                    CADASTRAR
                  </Button>
                </AreaButton>
              </Form>
            ))}
        </SectionRight>
      </Main>

    </Container>
  )
}