import styled from "styled-components";
import { Link } from "react-router-dom";

// Estilização Mobile First
const Container = styled.div`
  width: 100vw;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 481px) {
    height: 100vh;
  }
`
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 20px;

  @media only screen and (min-width: 769px) {
    width: 65vw;
    flex-direction: row;
  }
`
const SectionLeft = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: auto;
  background-color: #005693;
  color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 10px;

  @media only screen and (min-width: 769px) {
    width: 30vw;
    padding: 50px 20px;
    border-radius: 20px 0 0 20px;
  }
`
const Image = styled.img`
  max-width: 80px;
  
  @media only screen and (min-width: 769px) {
    max-width: 120px;
  }
`
const MyLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`
const TextTitle = styled.h3`
`
const TextDescription = styled.p`
`
const TextRegister = styled.h1`
  display: flex;
  justify-content: center;
  font-weight: bolder;
  color: #005693;
  font-size: 20px;

  @media only screen and (min-width: 769px) {
    font-size: 40px;
  }
`
const TextSpan = styled.span`
  color: #fff;
  font-style: italic;
  font-size: 12px;

  &:hover{
    text-decoration: underline;
  }
`
const SectionRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  
  @media only screen and (min-width: 769px) {
    width: 70vw;
    padding: 20px;
  } 

  button{
    background-color: #005693;
    border: 1px solid #005693;

    &:hover{
      background-color: rgba(0,86,147, 0.9);
      border: 1px solid rgba(0,86,147, 0.9);
      // filter: brightness(95%);
    }
  } 
`
const AreaButton = styled.div`
  display: flex;
  justify-content: center;
`
export {
  Container, Main, SectionLeft, Image, MyLink, TextTitle,
  TextDescription, TextRegister, TextSpan, SectionRight, AreaButton
}