import React from 'react';
import { Route, Switch } from "react-router";

import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { RecoverPassword } from './pages/RecoverPassword';
import { ResetPassword } from './pages/ResetPassword';
import { Search } from './pages/Search';
import { Upload } from './pages/Upload';

export default () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Login />
      </Route>
      <Route exact path='/register'>
        <Register />
      </Route>
      <Route exact path='/recoverPassword'>
        <RecoverPassword />
      </Route>
      <Route exact path='/resetPassword/:token'>
        <ResetPassword />
      </Route>
      <Route exact path='/search'>
        <Search />
      </Route>
      <Route exact path='/upload'>
        <Upload />
      </Route>
    </Switch>
  )
}