import styled from "styled-components";
import { Link } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BiLogOutCircle } from 'react-icons/bi';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { CgMenu } from 'react-icons/cg'

// Container Geral 
const Container = styled.div`

  margin: 0;
  padding: 0;
 @media only screen and (max-width: 768px) {
    #check:checked ~ .barra{
      transform: translateX(300px);
    }

    #check:checked ~ .barra nav a .link{
      opacity: 1;
      margin-top: 0;
      transition-delay: .2s;
    }
  }
`
// Menu Hamburger
const BurgerMenu = styled.div`
  display: block;
  position: absolute;
  z-index: 1;
  
  @media only screen and (min-width: 769px) {
    display: none;
  }
`
const IconLabel = styled.label`
  
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: 10vh;
    color: #fff;
    background-color: #005693;

    p{
      padding: 0;
      margin: 0;
    }
  }
`
const CheckInput = styled.input`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const NavigatorBar = styled.div`
  
  @media only screen and (max-width: 768px){
    background-color: #005693;
    height: 100vh;
    width: 300px;
    position: absolute;
    transition: all .2s linear;
    left: -300px;
  }

`
const NavArea = styled.nav`
  a{
    text-decoration: none;
  }

  @media only screen and (max-width: 768px){
    width: 100%;
    position: absolute;
    top: 10vh;
  }
`
const LinkArea = styled.div`
  @media only screen and (max-width: 768px) {
    background-color: rgb(0,170,187, .3);
    font-size: 12pt;
    padding: 20px;
    transition: all .2s linear;
    color: #f4f4f9;
    border-bottom: 2px solid #fff;
    opacity: 0;
    margin-top: 200px;

    &:hover{
    background-color: #005693;
    }
  }
`
// Menu Normal
const NormalMenu = styled.div`
  display: none;
  
  @media only screen and (min-width: 769px) {
    height: 100vh;
    width: 15vw;
    background-color: #005693;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const AreaLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35vh;
  width: 95%;
`
const ImageLogo = styled.img`
  width: 100px;
`
const LinhaHr = styled.hr`
  width: 100%;
  border: 1px solid #fff;
  margin-top: 2px;  
`

const MenuArea = styled.ul`
  height: 50vh;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const MenuItem = styled.li`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  
/*   background-color: ${props=>props.active ? '#00aabb' : 'transparent'};
  border: 1px solid #00aabb;
  padding: 5px;
  border-radius: 25px; */

  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
`
const MyLink = styled(Link)`
  text-decoration: none;
`
const WelcomeUserArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const WelcomeUser = styled.p`
  margin: 0;
`

// Icons
const ImageMenuBurger = styled(CgMenu)`
  width: 48px;
  height: 48px;
`
const ImageHome = styled(AiOutlineHome)`
  width: 32px;
  height: 32px;
`
const ImageSearch = styled(AiOutlineFileSearch)`
  width: 32px;
  height: 32px;
`
const ImageAttachment = styled(AiOutlinePaperClip)`
  width: 32px;
  height: 32px;
`
const ImageLogout = styled(BiLogOutCircle)`
  width: 32px;
  height: 32px;
`
export {
  Container, NormalMenu, AreaLogo, ImageLogo, LinhaHr, MenuArea,
  MenuItem, MyLink, BurgerMenu, IconLabel, CheckInput,
  NavigatorBar, NavArea, LinkArea, ImageMenuBurger,
  ImageHome, ImageSearch, ImageAttachment, ImageLogout, WelcomeUserArea, WelcomeUser
}